/*
*  Form for creating Facilities that contains all facility related information
*
* Copyright (C) 2018, 2019, 2020 Sterilis Medical Inc., all rights reserved.
*/
import React, { useEffect, useState } from "react";
import {FloatLabelInput, FloatLabelDropdown} from '../../library/FloatLabelInputs'
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import FindFacilityElevationPopup from "../FindFacilityElevationPopup";
import FacilityScheduleModal from "../../view/customers/modals/FacilityScheduleModal";
const shouldStateBeShown = country => {
  const countiesWithStates = ['us', 'ca'];
  return countiesWithStates.includes(country);
};

const FacilityForm = (props) => {
    const { watch, getValues, setValue } = useFormContext();
    const { t } = useTranslation("translations");
    const watchCountry = watch("facilityCountry", false);
    const watchState = watch("facilityState", false);

    const facilityTypeOptions = [
        {
            key: "RMW",
            value: "RMW",
            text: t("Regulated Medical Waste"),
        },
        {
            key: "airport",
            value: "airport",
            text: t("Airport"),
        },
        {
            key: "cannabis",
            value: "cannabis",
            text: t("Cannabis"),
        },
    ];

    const bioValidationWasteTypeOptions = [
        {
            key: "indicator",
            value: "indicator",
            text: t("Biological Challenge Indicator"),
        },
        {
            key: "sharps",
            value: "sharps",
            text: t("Biological Validation with Sharps"),
        },
        {
            key: "redbags",
            value: "redbags",
            text: t("Biological Validation with Red Bags"),
        },
        {
            key: "dialyzers",
            value: "dialyzers",
            text: t("Biological Validation with Dialyzers"),
        },
        {
        key: "soft_lab_waste",
        value: 'soft_lab_waste',
        text: t('Biological Validation with Soft Lab Waste')
      }
    ];

    const incubationPeriodOptions = [
        {
            key: "86400",
            value: "86400",
            text: t("24 hours"),
        },
        {
            key: "172800",
            value: "172800",
            text: t("48 hours"),
        },
    ];

    const countryOptions = [
        {
            key: "us",
            value: "us",
            text: t("United States"),
        },
        {
            key: "ar",
            value: "ar",
            text: t("Argentina"),
        },
        {
            key: "au",
            value: "au",
            text: t("Australia"),
        },
        {
            key: "ca",
            value: "ca",
            text: t("Canada"),
        },
        {
            key: "ec",
            value: "ec",
            text: t("Ecuador"),
        },
        {
            key: "in",
            value: "in",
            text: t("India"),
        },
        {
            key: "id",
            value: "id",
            text: t("Indonesia"),
        },
        {
            key: "sg",
            value: "sg",
            text: t("Singapore"),
        },
        {
            key: "za",
            value: "za",
            text: t("South Africa"),
        },
        {
            key: "vi",
            value: "vi",
            text: t("Virgin Islands (US)"),
        },
        {
            key: "ro",
            value: "ro",
            text: t("Romania"),
        },
    ];

    const wastePerMonthOptions = [
        {
            key: 0,
            value: "<51",
            text: t("1-50 pounds - BC every 3 months"),
        },
        {
            key: 1,
            value: "51-100",
            text: t("51-100 pounds - BC every 4 weeks"),
        },
        {
            key: 2,
            value: "101-200",
            text: t("101-200 pounds - BC every 2 weeks"),
        },
        {
            key: 3,
            value: ">200",
            text: t("> 200 pounds - BC every week"),
        },
    ];

    const facilityCountry = getValues()["facilityCountry"] || "";
    const facilityState = getValues()["facilityState"] || "";

    const [timezoneOptions, setTimezoneOptions] = useState([]);

    useEffect(() => {
        // check that the timezone array has an entry for this country
        if (props["timezones"][facilityCountry]) {
            const timezoneArray = props["timezones"][facilityCountry].reduce((timezoneArray, timezone) => {
                if (facilityState) {
                    if (timezone["state"] === facilityState) {
                        timezoneArray.push({
                            key: timezone["regionID"],
                            value: timezone["regionID"],
                            text: timezone["display_label"],
                        });
                        return timezoneArray;
                    } else {
                        return timezoneArray;
                    }
                } else {
                    timezoneArray.push({
                        key: timezone["regionID"],
                        value: timezone["regionID"],
                        text: timezone["display_label"],
                    });
                    return timezoneArray;
                }
            }, []);
            setTimezoneOptions(timezoneArray);
        }
    }, [watchCountry, watchState]);

    return (
        <div>
            <FloatLabelInput
                registerParams={{ required: "Required" }}
                required={true}
                name={"facilityName"}
                labelText={props["create"] === "distributor" ? t("Warehouse Name") : t("Facility Name")}
                errors={props["errors"]}
            />
            <FloatLabelInput
                registerParams={{
                    required: true,
                }}
                required={true}
                name={"facilityThoroughfare"}
                labelText={t("Address")}
            />

            <div className="two-by-two-grid">
                <FloatLabelInput
                    registerParams={{ required: false }}
                    required={true}
                    name={"facilitySubThoroughfare"}
                    labelText={t("Address line 2 (optional)")}
                    inputClass="forty-height"
                />
                <FloatLabelInput
                    registerParams={{ required: "Required" }}
                    required={true}
                    name={"facilityCity"}
                    labelText={t("City")}
                    inputClass="forty-height"
                />
                <FloatLabelDropdown
                    registerParams={{ required: "Required" }}
                    name={"facilityCountry"}
                    labelText={t("Country")}
                    options={countryOptions}
                    useFirstItemAsDefault={true}
                    onChangeCallback={() => {
                        // setValue('facilityState', '');
                        setValue("facilityTimezone", "");
                    }}
                    inputClass="forty-height"
                />
                {
                    shouldStateBeShown(facilityCountry) ? (
                        <div className="flex-dir-row">
                            <FloatLabelDropdown
                                registerParams={{ required: shouldStateBeShown(facilityCountry) }}
                                required={true}
                                options={props.stateDropdownOptions[facilityCountry]}
                                name={"facilityState"}
                                labelText={t("State")}
                                containerClass="full-width"
                                inputClass="forty-height"
                            />
                        </div>
                    ) : (
                        <div />
                    ) // if the state should not be shown, render a blank div here to keep the grid's shape
                }
                <FloatLabelInput
                    registerParams={{ required: "Required" }}
                    required={true}
                    name={"facilityZip"}
                    labelText={t("Zipcode")}
                    inputClass="forty-height"
                />
                {/*only render timezone div if */}
                {/*the facility country is not US or CA  */}
                {/*if it is US or CA, only render if there has been a value chosen for facilityState (which is an abbreviated string eg 'MA') */}
                {
                    !((facilityCountry === "us" || facilityCountry === "ca") && facilityState.length === 0) ? (
                        <FloatLabelDropdown
                            registerParams={{ required: "Required" }}
                            name={"facilityTimezone"}
                            labelText={t("Timezone")}
                            options={timezoneOptions}
                            inputClass="forty-height"
                            useFirstItemAsDefault={true}
                        />
                    ) : (
                        <div />
                    ) // render an empty div if tz should not be shown yet
                }
                <FloatLabelDropdown
                    registerParams={{ required: "Required" }}
                    name={"bioValidationWasteType"}
                    labelText={t("Biological Validation Waste Type")}
                    options={bioValidationWasteTypeOptions}
                    useFirstItemAsDefault={true}
                />
                <FloatLabelDropdown
                    registerParams={{ required: "Required" }}
                    name={"facilityType"}
                    labelText={t("Regulatory Market")}
                    options={facilityTypeOptions}
                    useFirstItemAsDefault={true}
                />
                <FloatLabelDropdown
                    registerParams={{ required: "Required" }}
                    name={"incubationPeriod"}
                    labelText={t("Incubation period")}
                    options={incubationPeriodOptions}
                    useFirstItemAsDefault={true}
                />
                <div className="flex-dir-row">
                    <FindFacilityElevationPopup iconClass="important-slight-margin-top" />
                    <FloatLabelInput
                        registerParams={{ required: "Required" }}
                        required={true}
                        type="number"
                        name={"facilityElevation"}
                        labelText={`${t("Elevation")} (ft)`}
                        inputClass="forty-height"
                        containerClass="full-width"
                    />
                </div>
                {facilityState === "TX" && (
                    <FloatLabelDropdown
                        registerParams={{ required: "Required" }}
                        name={"facilityWastePerMonth"}
                        labelText={t("Waste load per month")}
                        options={wastePerMonthOptions}
                        inputClass="forty-height"
                        selectOnBlur={false}
                    />
                )}
                <FacilityScheduleModal
                    onClose={() => props.setfacilityScheduleModal(false)}
                    facilityScheduleModal={props.facilityScheduleModal}
                    triggerFacilityScheduleModal={props.triggerFacilityScheduleModal}
                    setSchedule={props.setSchedule}
                    setFacilityScheduleModal={props.setFacilityScheduleModal}
                    setAllowSubmitFacility={props.setAllowSubmitFacility}
                    allowSubmitFacility={props.allowSubmitFacility}
                    facility={null}
                    setScheduleIconName={props.setScheduleIconName}></FacilityScheduleModal>
            </div>
        </div>
    );
};

export default FacilityForm;

FacilityForm.defaultProps = {
    timezones: [],
    stateDropdownOptions: {},
};
